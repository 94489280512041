<template>
    <div>
        <st-button
            v-can:create="'forms'"
            variant="secondary"
            :callback="createFrom"
        >
            <i class="fa fa-plus"></i>
            {{ $t('FORMS.LIST.ADD_BUTTON') }}
        </st-button>
    </div>
</template>

<script>
    export default {
        name: 'FormsListToolbar',
        methods: {
            createFrom() {
                this.$router.push({ name: 'formNew' });
            },
        },
    };
</script>

