<template>
    <st-page
        :title="$t('FORMS.LIST.TITLE')"
    >
        <loader v-if="isLoading"/>
        <template #toolbar>
            <forms-list-toolbar/>
        </template>
        <forms-list-filter>
            <forms-list-table/>
        </forms-list-filter>
    </st-page>
</template>

<script>
import FormsListFilter from '../components/FormsListFilter';
import FormsListTable from '../components/FormsListTable';
import FormsListToolbar from '../components/FormsListToolbar';
import { mapGetters } from "vuex";

export default {
    name: 'FormsList',
    components: {
        FormsListFilter,
        FormsListTable,
        FormsListToolbar,
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        loadingFetch() {
            return this.loading['forms/get'];
        },
        isLoading() {
            return this.loadingFetch;
        },
    },
}
</script>
