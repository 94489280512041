<template>
    <st-filters-pagination
        stateModule="forms/list"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :header="$t('FORMS.LIST.TABLE_HEADER')"
        :moreFilters="false"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { FormsModel } from '@/modules/forms/forms-model';

    const { fields } = FormsModel;

    const filterSchema = new FilterSchema([
        fields.name,
    ]);

    export default {
        name: 'FormsListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                currentPage: 1,
            };
        },
        computed: {
            ...mapGetters({
               total: 'forms/list/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'forms/list/doFetch',
            }),
            async doFilter(params) {
                this.doFetch(params);
            },
        },

    };
</script>

<style scoped>

</style>
