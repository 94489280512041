<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @edit="onEdit"
            @delete="onDelete"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FormsModel } from '@/modules/forms/forms-model';

    const { fields } = FormsModel;

    export default {
        name: 'FormsListTable',
        data() {
            return {
                presenter: FormsModel.presenter,
            };
        },
        computed: {
            ...mapGetters({
                rows: 'forms/list/rows',
                formsPermissions: 'forms/list/formsPermissions',
            }),
            fields() {
                return [
                    fields.name,
                    fields.status,
                ]
            },
            actions() {
                return [
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary',
                        permission: this.formsPermissions.hasPermissionToEdit
                    },
                    {
                        name: 'delete',
                        icon: 'trash',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        customIcon: false,
                        type: 'primary',
                        permission: this.formsPermissions.hasPermissionToDestroy
                    }
                ]
            }
        },
        methods: {
            ...mapActions({
                selectRecord: 'forms/form/select',
                deleteRecord: 'forms/form/remove',
            }),
            onEdit(data) {
                this.$router.push({
                    name: 'formView',
                    params: {
                        id: data.item.id,
                    }
                });
            },
            async onDelete(data) {
                await this.deleteRecord(data.item.id);
            },
        },
    };
</script>

<style scoped>

</style>
